import axios from "axios";
import { toast } from "react-toastify";
import ACCESS_TOKEN from "./jwt-token-access/accessToken";
//pass new generated access token here
// const token = ACCESS_TOKEN();

//apply base url for axios
// const API_URL = "https://redpillgolfapi.developer-rr.xyz/api";
// const API_URL = "https://red-pill-golf.developer-iu.xyz/api";
// const API_URL = "https://red-pill-golf.netwaveisp.com/api";
const API_URL = "https://red-pill-golf.sasstechnologies.com/api";



const axiosApi = axios.create({
  baseURL: API_URL,
});

const setAuthorizationHeader = () => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosApi.defaults.headers.common["Authorization"];
  }
};

// / Check the token on load or when it changes
const CheckToken = () => {
  setAuthorizationHeader();
};


// Event listener to handle token change from `localStorage`
window.addEventListener('storage', (event) => {
  if (event.key === "accessToken") {
    CheckToken();  // Update the Authorization header whenever token changes
    // Optionally handle logout if the token is removed or invalidated
    if (!localStorage.getItem("accessToken")) {
      window.location.href = '/login';  // Redirect to login if token is invalid
    }
  }
});

// On initial load, check for token and set Authorization header
CheckToken();

axiosApi.interceptors.response.use(
  response => response,
  error => {
    // Handle any errors here, like 401 (unauthenticated)
    if (error.response && error.response.status === 401) {
      window.location.href = '/login'; // Redirect to login page if unauthenticated
    }
    return Promise.reject(error);
  }
);


// Custom GET method to make API requests
export async function get(url, config = {}) {
  CheckToken();  // Ensure token is set before making the request
  try {
    const response = await axiosApi.get(url, { ...config });
    return response.data;
  } catch (err) {
    console.log("ErrList", err);
    if (err.response) {
      const Data = err.response.data;
      if (Data) {
        console.log('Error', Data.message);
        if (err.response.status === 401 || Data.message === "Unauthenticated") {
          console.log('Unauthorized, redirecting to login');
          // window.location.href = '/login';  // Redirect if unauthorized
        }

        // Handle validation errors
        const ErrList = Object.keys(Data.errors);
        ErrList.forEach((e) => toast.error(Data.errors[e][0]));
      } else {
        toast.error(Data.message);
      }
    } else {
      toast.error(err.message);
    }
  }
}


export async function post(url, data, config = {}) {
  CheckToken();
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)

    .catch(err => {
      console.log("ErrList", err);
      if (err.response !== undefined && err.response.data !== undefined) {
        const Data = err.response.data;
        if (Data.errors) {
          const ErrList = Object.keys(Data.errors);
          ErrList.map((e) => toast.error(Data.errors[e][0]));
        } else {
          toast.error(Data.message);
        }
      } else {
        toast.error(err.message);
      }
    })
}

export async function put(url, data, config = {}) {
  CheckToken();
  return axiosApi
    .put(url, data, config)
    .then(response => response.data).catch(err => {
      console.log("ErrList", err);
      if (err.response !== undefined) {
        const Data = err.response.data;
        if (Data.errors) {
          const ErrList = Object.keys(Data.errors);
          ErrList.map((e) => toast.error(Data.errors[e][0]));
        } else {
          toast.error(Data.message);
        }
      } else {
        toast.error(err.message);
      }
    });
}

export async function del(url, config = {}) {
  CheckToken();
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data).catch(err => {
      console.log("ErrList", err);
      if (err.response !== undefined) {
        const Data = err.response.data;
        if (Data.errors) {
          const ErrList = Object.keys(Data.errors);
          ErrList.map((e) => toast.error(Data.errors[e][0]));
        } else {
          toast.error(Data.message);
        }
      } else {
        toast.error(err.message);
      }
    });
}
